import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Configuration } from '../configuration';
import { filter, mergeMap, map, tap } from 'rxjs/operators';
import { AlertService, MessageType } from '../services/alert.service';
import { CurrentUserService } from '../services/current-user.service';


@Injectable({
    providedIn: 'root'
})
export class SubscriptionGuard {

    constructor(private userService: CurrentUserService, private alertService: AlertService, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return of(true);
        return this.userService.hasSubscriptionPlanPermissions(next.data.subscriptionPlan).pipe(
            tap(isPermitted => {
                if (!isPermitted) {
                    this.alertService.showMessage("Bieżąca subskrypcja nie pozwala na przejście do tej zakładki", MessageType.Error);
                    this.router.navigateByUrl("/my-company/payments");
                }
            })
        )
    }
}
