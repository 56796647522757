import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { MaterialModule } from "./modules/material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthorizationButtonComponent } from "./components/pages/login/authorization-button/authorization-button.component";
import { InternalAuthenticationComponent } from "./components/pages/login/internal-authentication/internal-authentication.component";
import { UsersComponent } from "./components/pages/users/users.component";
import { GroupsComponent } from "./components/pages/groups/groups.component";
import { UserWizardComponent } from "./components/pages/users/user-wizard/user-wizard.component";
import { routing } from "./app.routing";
import { GroupWizardComponent } from "./components/pages/groups/group-wizard/group-wizard.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { Configuration, ConfigurationParameters } from "./configuration";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DaysSelectorComponent } from "./components/days-selector/days-selector.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { UsersViewComponent } from "./components/pages/users/users-view/users-view.component";
import { LoggerConsoleComponent } from "./components/pages/home/logger-console/logger-console.component";
import { DynamicFileReaderComponent } from "./components/dynamic-file-reader/dynamic-file-reader.component";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";
import { ChooseLocationDialogComponent } from "./components/dialogs/choose-location-dialog/choose-location-dialog.component";
import { ApiModule } from "../../api";
import { SubjectListComponent } from "./components/pages/login/subject-list/subject-list.component";
import { AddSubjectDialogComponent } from "./components/pages/login/subject-list/add-subject-dialog/add-subject-dialog.component";
import { RegistrationComponent } from "./components/pages/registration/registration.component";
import { PersonSmallInfoComponent } from "./components/person-small-info/person-small-info.component";
import { CarsComponent } from "./components/pages/cars/cars.component";
import { RealisingNowComponent } from "./components/pages/realising-now/realising-now.component";
import { DriversComponent } from "./components/pages/drivers/drivers.component";
import { CarWizardComponent } from "./components/pages/cars/car-wizard/car-wizard.component";
import { DriverWizardComponent } from "./components/pages/drivers/driver-wizard/driver-wizard.component";
import { EditDataDialogComponent } from "./components/pages/home/kpo/edit-data-dialog/edit-data-dialog.component";
import { ViewDraftCardDialogComponent } from "./components/pages/home/kpo/view-draft-card-dialog/view-draft-card-dialog.component";
import { SelectReceiverDialogComponent } from "./components/pages/home/kpo/select-receiver-dialog/select-receiver-dialog.component";
import { RejectCardDialogComponent } from "./components/pages/home/kpo/reject-card-dialog/reject-card-dialog.component";
import { SelectDriverDialogComponent } from "./components/pages/realising-now/select-driver-dialog/select-driver-dialog.component";
// tslint:disable-next-line: max-line-length
import { GenerateConfirmationDialogComponent } from "./components/pages/realising-now/generate-confirmation-dialog/generate-confirmation-dialog.component";
import { SubjectSelectionComponent } from "./components/pages/subject-selection/subject-selection.component";
import { HeadquarterSelectionComponent } from "./components/pages/headquarter-selection/headquarter-selection.component";
import { HeadquarterListComponent } from "./components/pages/headquarter-selection/headquarter-list/headquarter-list.component";
import { CompanyDetailsComponent } from "./components/pages/company-details/company-details.component";
// tslint:disable-next-line: max-line-length
import { ConfirmationDownloaderComponent } from "./components/pages/realising-now/confirmation-downloader/confirmation-downloader.component";
import { APP_BASE_HREF, DatePipe, LocationStrategy, PathLocationStrategy, registerLocaleData } from "@angular/common";
import { KpoDetailsDialogComponent } from "./components/pages/archive/archive-details-dialog/kpo-details-dialog.component";
import { ArchiveComponent } from "./components/pages/archive/archive.component";
import { MatSortModule } from "@angular/material/sort";
import { UserWizardDialogComponent } from "./components/dialogs/user-wizard-dialog/user-wizard-dialog.component";
import { environment } from "../environments/environment";
import { MessagingService } from "./services/messaging.service";
import { DispatcherWizardComponent } from "./components/pages/drivers/dispatcher-wizard/dispatcher-wizard.component";
import { SubjectWizardComponent } from "./components/pages/users/subject-wizard/subject-wizard.component";
import { StatsComponent } from "./components/pages/stats/stats.component";
import { ConfirmReciveDialogComponent } from "./components/pages/realising-now/confirm-recive-dialog/confirm-recive-dialog.component";
import { StorageServiceModule } from "ngx-webstorage-service";
import { CookieService } from "ngx-cookie-service";
import { PasswordRecoveryComponent } from "./components/pages/password-recovery/password-recovery.component";
import { RejectReceiveDialogComponent } from "./components/pages/realising-now/reject-receive-dialog/reject-receive-dialog.component";
import { WhitelistComponent } from "./components/pages/whitelist/whitelist.component";
import { ConfirmDialogComponent } from "./components/dialogs/confirm-dialog/confirm-dialog.component";
import { EulaAcceptanceDialogComponent } from "./components/dialogs/eula-acceptance-dialog/eula-acceptance-dialog.component";
import { UserActionsLogComponent } from "./components/pages/user-actions-log/user-actions-log.component";
import { SubjectFilterPipe } from "./components/pages/subject-selection/filter.pipe";
import { NewKpoDialogComponent } from "./components/pages/home/kpo/new-kpo-dialog/new-kpo-dialog.component";
import { CardBlockComponent } from "./components/card-block/card-block.component";
import { CardBlockFieldComponent } from "./components/card-block-field/card-block-field.component";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { BdoFilterComponent } from "./components/bdo-filter/bdo-filter.component";
// tslint:disable-next-line:max-line-length
import { AddWhitelistSubjectDialogComponent } from "./components/pages/whitelist/add-whitelist-subject-dialog/add-whitelist-subject-dialog.component";
import { MobileFooterComponent } from "./components/mobile-footer/mobile-footer.component";
import { CompanyPickerComponent } from "./components/company-picker/company-picker.component";
import { HeadquarterPickerComponent } from "./components/headquarter-picker/headquarter-picker.component";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { FilingComponent } from "./components/pages/filing/filing.component";
import { FilingConfirmationDialogComponent } from "./components/dialogs/filing/filing-confirmation-dialog/filing-confirmation-dialog.component";
import { FiledCardsComponent } from "./components/pages/filed-cards/filed-cards.component";
import { WasteRecordCardsComponent } from "./components/pages/waste-record-cards/waste-record-cards.component";
import { FiledCardDetailsComponent } from "./components/dialogs/filed-card-details/filed-card-details.component";
import { ValueFieldComponent } from "./components/value-field/value-field.component";
import { FiledGeneratedWasteTransferCardsComponent } from "./components/dialogs/filed-generated-waste-transfer-cards/filed-generated-waste-transfer-cards.component";
import { FiledCollectedWasteTransferCardsComponent } from "./components/dialogs/filed-collected-waste-transfer-cards/filed-collected-waste-transfer-cards.component";
import { FiledForwardedWasteTransferCardsComponent } from "./components/dialogs/filed-forwarded-waste-transfer-cards/filed-forwarded-waste-transfer-cards.component";
import { RolePickerComponent } from "./components/role-picker/role-picker.component";
import { ConfirmWithdrawalDialogComponent } from "./components/pages/realising-now/confirm-withdrawal-dialog/confirm-withdrawal-dialog.component";
import { ReviseCardDialogComponent } from "./components/pages/realising-now/revise-card-dialog/revise-card-dialog.component";
import { RoleService } from "./services/role.service";
import { ChangeRoleDialogComponent } from "./components/dialogs/change-role-dialog/change-role-dialog.component";
import { LandingPageComponent } from "./components/pages/landing-page/landing-page.component";
import { Interceptor } from "./interceptor";
import { ReportComponent } from "./components/pages/report/report.component";
import { WasteCodeDirective } from "./components/pages/report/waste-code.directive";
import { WasteTransferCardsComponent } from "./components/pages/waste-transfer-cards/waste-transfer-cards.component";
import { PlannedCardApprovalDialogComponent } from './components/dialogs/planned-card-approval-dialog/planned-card-approval-dialog.component';
import { PlannedCardEditDialogComponent } from './components/dialogs/planned-card-edit-dialog/planned-card-edit-dialog.component';
import { PlannedCardRemovalDialogComponent } from './components/dialogs/planned-card-removal-dialog/planned-card-removal-dialog.component';
import { PasswordRecoveryInitStepComponent } from './components/pages/login/password-recovery-init-step/password-recovery-init-step.component';
import { SubscriptionsComponent } from './components/pages/subscriptions/subscriptions.component';
import { PaymentsComponent } from './components/pages/payments/payments.component';
import { NewSubscriptionDialogComponent } from './components/pages/subscriptions/new-subscription-dialog/new-subscription-dialog.component';
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    apiKeys: { Authorization: undefined },
    basePath: window.location.protocol + "//" + window.location.host,
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AuthorizationButtonComponent,
    InternalAuthenticationComponent,
    UsersComponent,
    GroupsComponent,
    UserWizardComponent,
    GroupWizardComponent,
    DaysSelectorComponent,
    UsersViewComponent,
    LoggerConsoleComponent,
    DynamicFileReaderComponent,
    ChooseLocationDialogComponent,
    SubjectListComponent,
    AddSubjectDialogComponent,
    RegistrationComponent,
    ArchiveComponent,
    KpoDetailsDialogComponent,
    PersonSmallInfoComponent,
    CarsComponent,
    RealisingNowComponent,
    DriversComponent,
    CarWizardComponent,
    DriverWizardComponent,
    EditDataDialogComponent,
    ViewDraftCardDialogComponent,
    SelectReceiverDialogComponent,
    RejectCardDialogComponent,
    SelectDriverDialogComponent,
    GenerateConfirmationDialogComponent,
    SubjectSelectionComponent,
    HeadquarterSelectionComponent,
    HeadquarterListComponent,
    CompanyDetailsComponent,
    ConfirmationDownloaderComponent,
    UserWizardDialogComponent,
    DispatcherWizardComponent,
    SubjectWizardComponent,
    StatsComponent,
    ConfirmReciveDialogComponent,
    PasswordRecoveryComponent,
    RejectReceiveDialogComponent,
    WhitelistComponent,
    AddWhitelistSubjectDialogComponent,
    ConfirmDialogComponent,
    EulaAcceptanceDialogComponent,
    UserActionsLogComponent,
    SubjectFilterPipe,
    NewKpoDialogComponent,
    CardBlockComponent,
    CardBlockFieldComponent,
    DashboardComponent,
    BdoFilterComponent,
    MobileFooterComponent,
    CompanyPickerComponent,
    HeadquarterPickerComponent,
    FilingComponent,
    FilingConfirmationDialogComponent,
    FiledCardsComponent,
    WasteRecordCardsComponent,
    FiledCardDetailsComponent,
    ValueFieldComponent,
    FiledGeneratedWasteTransferCardsComponent,
    FiledCollectedWasteTransferCardsComponent,
    FiledForwardedWasteTransferCardsComponent,
    RolePickerComponent,
    ConfirmWithdrawalDialogComponent,
    ReviseCardDialogComponent,
    ChangeRoleDialogComponent,
    LandingPageComponent,
    ReportComponent,
    WasteCodeDirective,
    WasteTransferCardsComponent,
    PlannedCardApprovalDialogComponent,
    PlannedCardEditDialogComponent,
    PlannedCardRemovalDialogComponent,
    PasswordRecoveryInitStepComponent,
    SubscriptionsComponent,
    PaymentsComponent,
    NewSubscriptionDialogComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routing, { relativeLinkResolution: "legacy" }),
    MaterialModule,
    BrowserAnimationsModule,
    ScrollingModule,
    ApiModule.forRoot(apiConfigFactory),
    CKEditorModule,
    FlexLayoutModule,
    MatSortModule,
    StorageServiceModule,
    AngularFireMessagingModule,
    MatInputModule,
    MatFormFieldModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [DatePipe, CookieService, MessagingService, { provide: MAT_DATE_LOCALE, useValue: "pl-PL" }, RoleService, { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule { }
